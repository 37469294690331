import * as React from 'react'
import {
  BrowserRouter,
  HashRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import queryString from 'query-string'
import { createTheme, ThemeProvider } from '@mui/material'
import {
  authService,
  Sentry,
  useTenantCivicPlus,
  useTenantOneBlink,
} from '@oneblink/apps'
import {
  IsOfflineContextProvider,
  AuthContextProvider,
  DraftsContextProvider,
  PendingSubmissionsContextProvider,
} from '@oneblink/apps-react'
import { ToastContainer } from 'react-toastify'

import {
  menuItems,
  jobsLabel,
  isPendingQueueEnabled,
  formsListHref,
  scheduledTasksGroupsHref,
} from 'services/menu-items-service'
import {
  RouteNotFound,
  BottomNavigation,
  HeaderNavigation,
  AndroidBackHandler,
  ScrollRestorationHandler,
  PendingQueueNotification,
} from 'components'
import { JobsProvider } from 'hooks/useJobs'
import { IsAuthorisedContextProvider } from 'hooks/useIsAuthorised'
import { NotificationsProvider } from 'hooks/useNotifications'
import Callback from 'authentication/Callback'
import LogoutScene from 'authentication/Logout'
import SignOutScene from 'authentication/SignOutScene'
import LoginScene from 'authentication/LoginScene'
import SignUpScene from 'authentication/SignUpScene'
import PaymentReceiptContainer from 'payment-receipt'
import PaymentFormScene from 'payment-form/PaymentFormScene'
import SchedulingReceipt from 'scheduling-receipt'
import SchedulingCancel from 'scheduling-cancel'
import FormScene from 'renderer/FormScene'
import ScheduledTaskGroupsInstance from 'scheduled-task-group-instances/ScheduledTaskGroupInstanceScene'
import TileList from 'tile-list'
import 'services/cypress'
import MaintenanceMessage from 'MaintenanceMessage'

window.ONEBLINK_APPS_ENVIRONMENT =
  import.meta.env.VITE_ONEBLINK_APPS_ENVIRONMENT
switch (import.meta.env.VITE_ONEBLINK_APPS_TENANT) {
  case 'civicplus': {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTenantCivicPlus()
    break
  }
  case 'oneblink':
  default: {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTenantOneBlink()
    break
  }
}

const formsAppId = window.formsHostnameConfiguration?.formsAppId || NaN
const formsAppType = window.formsHostnameConfiguration?.type
authService.init({
  oAuthClientId:
    window.formsHostnameConfiguration?.formsOAuthClientId || 'UNKNOWN',
})
if (window.formsHostnameConfiguration?.isClientLoggingEnabled) {
  Sentry.init(formsAppId, {
    dsn: import.meta.env.VITE_ONEBLINK_APPS_SENTRY_DSN,
    environment: import.meta.env.VITE_ONEBLINK_APPS_SENTRY_ENVIRONMENT,
    release: `${import.meta.env.VITE_ONEBLINK_APPS_TENANT}-${import.meta.env.VITE_ONEBLINK_APPS_VERSION}`,
    attachStacktrace: true,
  })
}

const muiTheme = createTheme({
  palette: {
    mode: 'light',
    success: {
      main: '#3a833c',
    },
    info: {
      main: '#2d7ab9',
    },
    error: {
      main: '#E8113C',
    },
    primary: {
      main:
        window.formsHostnameConfiguration?.styles?.highlightColour || '#407E8C',
    },
    secondary: {
      main:
        window.formsHostnameConfiguration?.styles?.foregroundColour ||
        '#407E8C',
    },
    warning: {
      main: '#B85000',
    },
  },
})

const routes: React.ReactNode[] = []
if (window.formsHostnameConfiguration) {
  if (window.formsHostnameConfiguration?.isAppUserSignUpEnabled) {
    routes.push(
      <Route key="sign-up" path="/sign-up" exact>
        <SignUpScene />
      </Route>,
    )
  }
  routes.push(
    ...menuItems.map(
      (menuItem) =>
        menuItem.routeComponent && (
          <Route key={menuItem.href} path={menuItem.href} exact>
            {menuItem.routeComponent}
          </Route>
        ),
    ),
    <Route
      key="forms"
      path="/forms"
      exact
      render={({ location }) => (
        <Redirect
          to={{
            ...location,
            pathname: formsListHref,
          }}
        />
      )}
    ></Route>,
    <Route key="form" path="/forms/:formId" exact>
      <FormScene formsAppId={formsAppId} />
    </Route>,
    <Route key="payment" path="/forms/:formId/payment-receipt" exact>
      <PaymentReceiptContainer />
    </Route>,
    <Route key="scheduling" path="/forms/:formId/scheduling-receipt" exact>
      <SchedulingReceipt />
    </Route>,
    <Route
      key="scheduling-cancel"
      path="/forms/:formId/scheduling-cancel-booking"
      exact
    >
      <SchedulingCancel />
    </Route>,
    <Route
      key="scheduled-task-group-task"
      path={`${scheduledTasksGroupsHref}/:taskGroupInstanceId`}
    >
      <ScheduledTaskGroupsInstance />
    </Route>,
    <Route key="login" path="/login" exact>
      <LoginScene />
    </Route>,
    <Route key="callback" path="/callback" exact>
      <Callback />
    </Route>,
    <Route key="logout" path="/logout" exact>
      <LogoutScene />
    </Route>,
    <Route key="signout" path="/signout" exact>
      <SignOutScene />
    </Route>,
    <Redirect key="redirect" to="/not-found" />,
  )
  if (window.formsHostnameConfiguration?.type === 'TILES') {
    routes.unshift(
      <Route key="home" path="/" exact>
        <TileList tiles={menuItems} />
      </Route>,
    )
  }
}

const Router: typeof BrowserRouter = window.cordova ? HashRouter : BrowserRouter

const query = queryString.parse(window.location.search)
const formsKeyToken =
  typeof query.access_key === 'string' ? query.access_key : undefined
const userToken =
  typeof query.userToken === 'string' ? query.userToken : undefined

export default function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <IsOfflineContextProvider>
        <MaintenanceMessage>
          <Router>
            <AndroidBackHandler />
            <ScrollRestorationHandler />
            <Switch>
              <Route path="/not-found" exact>
                <RouteNotFound />
              </Route>
              <Route path="/payment-form" exact>
                <PaymentFormScene />
              </Route>
              {!!routes.length && (
                <Route>
                  <AuthContextProvider
                    formsKeyToken={formsKeyToken}
                    userToken={userToken}
                  >
                    <IsAuthorisedContextProvider formsAppId={formsAppId}>
                      <DraftsContextProvider
                        formsAppId={formsAppId}
                        isDraftsEnabled={
                          !!window.formsHostnameConfiguration?.isDraftsEnabled
                        }
                      >
                        <PendingSubmissionsContextProvider
                          isPendingQueueEnabled={isPendingQueueEnabled}
                          successNotificationTimeoutMs={5000}
                        >
                          <JobsProvider
                            formsAppId={formsAppId}
                            jobsLabel={jobsLabel}
                          >
                            <NotificationsProvider formsAppId={formsAppId}>
                              {formsAppType === 'TILES' ? (
                                <HeaderNavigation>
                                  <Switch>{routes}</Switch>
                                </HeaderNavigation>
                              ) : (
                                <BottomNavigation>
                                  <Switch>{routes}</Switch>
                                </BottomNavigation>
                              )}
                            </NotificationsProvider>
                          </JobsProvider>
                          <PendingQueueNotification />
                        </PendingSubmissionsContextProvider>
                      </DraftsContextProvider>
                    </IsAuthorisedContextProvider>
                  </AuthContextProvider>
                </Route>
              )}
              <Redirect to="/not-found" />
            </Switch>
          </Router>
          <ToastContainer newestOnTop={false} />
        </MaintenanceMessage>
      </IsOfflineContextProvider>
    </ThemeProvider>
  )
}
